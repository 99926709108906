<template>
  <div class="h-screen flex w-full bg-img">
     <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
       <vx-card>
         <div slot="no-body" class="full-page-bg-color">
           <div class="vx-row">
             <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
               <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
             </div>


             <div  class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
               <div class="p-8">
                 <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Please Enter Your New Password.</h4>
                 </div>
                 <vs-input v-model="email" label-placeholder="Email" class="w-full mb-8" />
                 <vs-input v-model="confirmPassword" label-placeholder="Confirm Password" class="w-full mb-8" type="password" />
                <vs-input v-model="newPassword" label-placeholder="New Password" class="w-full mb-8" type="password" />
                 <div class="flex flex-wrap items-center justify-end">
                  <vs-button @click.stop="changePassword()" class="ml-auto mt-2">Save Changes</vs-button>
                  <vs-button @click="cancel" class="ml-4 mt-2" type="border" color="warning">Cancel</vs-button>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </vx-card>
     </div>
  </div>
 </template>

 <script>
 import moduleAuth from "@/store/auth/moduleAuth.js";
 export default {
  data() {
 return {
    email: '',
    newPassword: '',
    confirmPassword: '',
 };
},
computed: {
 passwordsMatch() {
    return this.newPassword === this.confirmPassword;
 }
},

  methods: {
     cancel() {
       this.$router.go(-1);
     },

 changePassword() {
    if (!this.passwordsMatch) {
      alert('Passwords do not match');
      return;
    }
    this.$store.dispatch("moduleAuthList/ResetPassword", {
 email: this.email,
 newPassword: this.newPassword
}).then(response => {
 // Handle the response from the action
 console.log(response);
}).catch(error => {
 // Handle any errors that occurred during the action
 console.error(error);
});

 }
 },

 created() {
      if (!moduleAuth.isRegistered) {
        this.$store.registerModule("moduleAuthList", moduleAuth);
        moduleAuth.isRegistered = true;
      }
    },
    mounted() {
      this.isMounted = true;
    },
};

 </script>
